@import '../../Styles/Variables.sass';

#vorteile {
	.image--article {
		background-size: cover; }
	.vorteile--grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: repeat(3, 20rem);
		grid-gap: 1rem;
		grid-template-areas: 'auszeichnung standorte' 'benefits gehalt' 'einarbeitung bild';
		@media (min-width: $tablet) {
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-rows: repeat(2, 35rem);
			grid-template-areas: 'auszeichnung standorte benefits' 'gehalt einarbeitung bild'; }
		article {
			&:nth-child(1) {
				grid-area: auszeichnung; }
			&:nth-child(2) {
				grid-area: standorte; }
			&:nth-child(3) {
				grid-area: benefits; }
			&:nth-child(4) {
				grid-area: gehalt; }
			&:nth-child(5) {
				grid-area: einarbeitung; }
			&:nth-child(6) {
				grid-area: bild; } } } }

