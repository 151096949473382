@import 'Styles/Variables';
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Lato', sans-serif;
	text-decoration: none; }

body, html {
	font-size: 10px;
	h2 {
		color: $main;
		font-size: 5rem;
		text-align: center;
		margin-bottom: 3rem; }
	p {
		font-size: 1.6rem; }
	a {
		font-size: inherit;
		color: inherit;
		text-decoration: none; }

	.content {
		width: 100%;
		max-width: 120rem;
		margin: 0 auto 10vw;
		padding: 0 1rem; } }
