@import '../../../Styles/Variables.sass';

.single--job {
	position: relative;
	width: 100%;
	max-width: 30rem;
	margin: 0 auto;
	align-items: flex-end;
	height: 40rem;
	background-position: right center;
	background-size: cover;
	overflow: hidden;
	display: none;
	animation: fadeIn forwards 500ms ease;
	@media (min-width: $tablet) {
		display: flex; }
	.job--main {
		transition: all 300ms ease;
		background: $red;
		padding: 2rem 1rem;
		width: 100%;
		position: relative;
		min-height: 1%;
		&:before {
			content: "";
			position: absolute;
			display: block;
			width: 6rem;
			height: 4rem;
			background: linear-gradient(-33deg, $red 49%, transparent 50%);
			left: 2rem;
			top: 0;
			transform: translateY(-100%); }

		* {
			color: white!important; }
		h4 {
			max-height: 20rem;
			transition: all 300ms ease;
			overflow: hidden;
			margin-bottom: 0; }
		.job--content {
			font-size: 1.6rem;
			line-height: 1.4;
			max-height: 0rem;
			transition: all 300ms ease;
			overflow: hidden; } }
	&:hover {
		.job--main {
			min-height: 100%;
			background: rgba($red, 0.9);
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			h4 {
				max-height: 0rem;
				overflow: hidden; }
			.job--content {
				display: flex;
				align-items: center;
				justify-content: center;
				max-height: 40rem; }

			&:before {
				background: linear-gradient(-33deg, rgba($red, 0.9) 49%, transparent 50%); } } }
	&.active {
		display: flex; } }


@keyframes fadeIn {
	from {
		opacity: 0;
		transform-origin: center;
		transform: rotateY(90deg); }
	to {
		opacity: 1;
		transform: rotateY(0); } }
