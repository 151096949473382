@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,900;1,300&display=swap');
$main: #0090d0;
$red: #D13B0A;
$lgrey: #F4F4F4;
$lblue: #C7E7F5;

$border: 3px;

$mobile: 620px;
$tablet: 960px;
$desktop: 1260px;

