@import '../../Styles/Variables';

#bewerben {
	background: white;
	position: relative;
	.left--content {
		padding: 2rem;
		margin-left: 0rem;
		padding-left: 2rem;
		position: relative;
		background: white;

		@media (min-width: $mobile) {
			margin-left: 6rem;
			padding-left: 4rem;
			&:before {
				content: ""; } }
		.form--content {
			@media (min-width: $tablet) {
				width: 100%;
				max-width: 60rem;
				margin: 0 0 0 auto; } }

		h2 {
			max-width: 100%;
			text-align: left; }
		p {
			margin-bottom: 4rem; }
		text-align: left;
		form {
			display: block; } }
	.right--content {
		display: none; }
	@media (min-width: $tablet) {
		display: flex;
		.left--content {
			width: 50%; }
		.right--content {
			width: 50%;
			display: block;
			background-size: cover;
			background-position: right center; } } }


