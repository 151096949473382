@import '../../Styles/Variables';

#videokanal {
	background: $lblue;
	@media (min-width: $mobile) {
		background: linear-gradient(to right, $main 30%, $lblue 30%);
		margin-top: 6rem; }
	.content {
		padding: 0;
		margin-bottom: 0;
		@media (min-width: $mobile) {
			display: grid;
			grid-template-areas: 'left right';
			grid-template-columns: 1fr 2fr; } }
	.kanalinfo {
		grid-area: left;
		background: $main;
		padding: 2rem;
		@media (min-width: $desktop) {
			padding-left: 0; }
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.kanalinfo--content {
			width: 100%;
			h3 {
				color: white;
				font-size: 3rem;
				margin-bottom: 2rem; }
			p {
				color: white; } } }
	.videocontent {
		grid-area: right;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4vw;
		@media (min-width: $desktop) {
			padding-right: 0; }
		.content--wrapper {
			width: 100%;
			max-width: 100%; }
		.iframe--wrapper {
			width: 100%;
			position: relative;
			height: 0;
			padding-bottom: 56.25%;
			iframe {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%; } } } }
